import React from "react"
import Layout from "../../components/layout"
import { Link, graphql } from "gatsby"
import SEO from "../../components/seo"
import ProseDark from "../../components/prose-dark"
import ProseLight from "../../components/prose-light"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

class WebsitesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      start: false,
      loading: true
    };
  }

  componentDidMount() {
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    sleep(100).then(() => {
        this.setState({ loading: false });
    })
    sleep(300).then(() => {
        this.setState({ start: true });
    })

    // if (this.video) {
    //   this.video.addEventListener("loadeddata", () => {
    //     this.setState({ loading: false });
    //   });
    // }
  }

  componentWillUnmount() {
    // if (this.video) {
    //   this.video.removeEventListener("loadeddata", () => {
    //   });
    // }
  }
  
  render() {
    const photo  = getImage(this.props.data.website)

  return(
  <Layout>
    <SEO title="District Websites" />
    <div className="bg-midnight w-full px-8 py-36">
    <div className="m-auto w-full container">
      <ProseDark>
        <div className="grid md:grid-cols-2 gap-12">

        <div>
        <div className="text-base mb-4 -mt-4">
          <Link className="inline-block no-underline hover:text-yellow-400 hover:underline text-white font-normal" to="/">Home</Link>
          <span className="opacity-50"> <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg> District Websites</span>
        </div>
        <h1 className="text-5xl md:text-6xl text-yellow-400 leading-none">District Websites
</h1>
        </div>
        <div>

        <p>Districts like yours are starting to consider what communication means to them. Do they have a website? Do they post to social media? How do they communicate important information not only in emergencies, but in the times between?</p>
        <p>By building a website to host information about the District, residents can find the information they are looking for and connect with the District easily. Touchstone offers customized options for district websites, all while maintaining website compliance.</p>
        </div>
        </div>
      </ProseDark>
    </div>
    </div>
    <div className="bg-zinc-100 w-full px-8 pb-36">
    <div className="m-auto w-full container">
      <ProseLight>
        <GatsbyImage className="!block relative bottom-20 m-auto w-full lg:w-2/3 aspect-video rounded-3xl" image={photo} alt="" />
        <div className="m-auto w-full lg:w-2/3 mt-12 leading-loose">

        <h1 className="text-6xl leading-none">Legal and Legislative Compliance
</h1>
        <p className="font-medium">Because Touchstone specializes in communications for special purpose districts in Texas, the legal and subtle differences necessary for effective public sector communications are well known. Touchstone goes beyond the standard website vendor and takes the worry out of having a district website by providing document monitoring and ensuring compliance. Touchstone takes the necessary steps to ensure the district website is legally compliant and following regulations. The website will be optimized for screen readers as part of ADA compliance. Websites will be regularly scheduled for site review to ensure compliance with all regulatory standards, taking the burden off the attorney and directors and placing it on Touchstone.</p>
        <p className="font-medium">Touchstone has different website offerings, and we would like to partner with your Board and consultants to develop an actively managed communication platform that best suits your District’s needs. Contact us today for a website consultation.</p>
        <p className="text-center">
          <Link className="transition-all inline-block rounded-full px-6 py-2 bg-yellow-500 hover:bg-yellow-400 text-black no-underline" to="/contact-us">Let's Schedule a Meeting</Link>
        </p>

        </div>

      </ProseLight>
    </div>
    </div>
  </Layout>
)
}
}

export default WebsitesPage

export const websiteQuery = graphql`
query websiteQuery {
  website: file(relativePath: {eq: "website-photo.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        placeholder: TRACED_SVG, 
        width: 640,
        quality: 95
        )
    }
  }
}`